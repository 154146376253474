<template>
  <v-row justify="center">
    <v-dialog v-model="dialog" max-width="600px" width="600px">
      <template v-slot:activator="{ on }">
        <v-icon color="black" v-on="on" v-if="skillId" @click="getSkillUsingId">
          mode_edit
        </v-icon>
        <v-icon color="black" v-on="on" v-else>
          add
        </v-icon>
      </template>
      <v-card outlined>
        <v-form v-model="valid" ref="createEditSkillForm">
          <v-card-title>
            <v-row>
              <v-col cols="10">
                <span class="text-h5 font-weight-bold mx-n1" v-if="!skillId"
                  >Add {{ level }} Skill <Tooltip :tooltipText="tooltipText" />
                </span>
                <span class="text-h5 font-weight-bold mx-3" v-else
                  >Edit {{ level }} Skill <Tooltip :tooltipText="tooltipText" />
                </span>
              </v-col>
              <v-col cols="1">
                <v-icon color="red" @click="onClose">
                  cancel
                </v-icon></v-col
              >
              <v-col cols="1">
                <v-icon color="green" @click="createSkills" v-if="!skillId">
                  done
                </v-icon>
                <v-icon color="blue" v-else @click="editSkills">
                  done
                </v-icon>
              </v-col>
            </v-row>
          </v-card-title>
          <v-divider></v-divider>

          <v-card-text>
            <v-row>
              <div class="text-h6 font-weight-bold mx-4">
                {{ level }} skill name<span class="red--text">*</span>
              </div>
            </v-row>
            <v-row class="mx-1">
              <v-text-field
                placeholder="Type here . . . . "
                outlined
                label="Add Skill Name"
                v-model="skill.skillName"
                solo
                required
                :rules="rules.isRequired"
              ></v-text-field>
            </v-row>
            <v-row>
              <div class="text-h6 font-weight-bold mx-4">
                Add synonym skill name<span class="red--text"></span>
              </div>
            </v-row>
            <v-row>
               <small class="text-h8 font-weight-bold grey--text mx-4">After typing press enter or tab to input more values</small>
            </v-row>
            <v-row>
              <v-row class="mx-1">
                <v-col>
                  <v-combobox
                    class="elevation-0"
                    label=""
                    chips
                    outlined
                    v-model="skill.skillSynonyms"
                    multiple
                    clearable
                    placeholder="Type here..."
                  >
                    <template slot="selection" slot-scope="data">
                      <v-chip
                        :input-value="select"
                        close
                        @click:close="removeSynonyms(data.item)"
                        text-color="black"
                        small
                      >
                        <strong>{{ data.item }}</strong>
                      </v-chip>
                    </template>
                  </v-combobox>
                </v-col>
              </v-row>
            </v-row>
          </v-card-text>

          <v-card-actions>
            <v-spacer></v-spacer>
            <!-- <v-btn
            outlined
            text
            rounded
            @click="onClose"
            color="white"
            style="background-color:#0D47A1"
            >Close</v-btn
          > -->
            <!-- <v-btn
            outlined
            text
            rounded
            @click="createSkills"
            v-if="!skillId"
            color="white"
            style="background-color:#0D47A1"
            >Save</v-btn
          >
          <v-btn
            outlined
            text
            rounded
            @click="editSkills"
            v-else
            color="white"
            style="background-color:#0D47A1"
            >Update Skill</v-btn
          > -->
          </v-card-actions>
          <v-snackbar
            :absolute="absoluteSnak"
            :bottom="true"
            :color="mode"
            :value="snackbar"
            :timeout="timeout"
            rounded
          >
            {{ notice }}
            <v-btn text @click.native="closeSnackbar" rounded outlined
              >Close</v-btn
            >
          </v-snackbar>
        </v-form>
      </v-card>
    </v-dialog>
  </v-row>
</template>
<script>
import { mapState } from "vuex";
import Tooltip from "./Tooltip/tooltip.vue";
export default {
  name: "CreateSkills",
  props: ["level", "parentId", "skillId"],
  data() {
    return {
      valid: false,
      dialog: false,
      timeout: 5000, //  5 seconds
      absoluteSnak: true,
      select: ["add-tags-with", "enter", "tab", "paste", "comma"],
      tooltipText: "Allowed special character:( ) -  _  /  . space number",
      rules: {
        isRequired: [(v) => !!v || "Required"]
      }
    };
  },
  components: {
    Tooltip
  },
  created() {
    // console.log('parent id ', this.parentId , 'level' , this.level ,'skillid' , this.skillId);
  },
  computed: {
    ...mapState("skillTree", {
      skill: "skill",
      mode: "mode", // color of snack bar
      notice: "notice", // text inside snack bar
      snackbar: "snackbar" // visibility of snack bar
    })
  },
  methods: {
    cb() {
      this.dialog = false;
      this.$refs.createEditSkillForm.reset();
    },
    onClose() {
      this.$store.dispatch("skillTree/createSkill", {
        skill: null,
        cb: this.cb
      });
    },
    modifySkillName(skillName) {
      if (skillName) {
        skillName = skillName.trim();
        if (skillName.charAt(skillName.length - 1) == ",") {
          skillName = skillName.substring(0, skillName.length - 1);
        }
        skillName = skillName.replaceAll("_", " ");
        // skillName = skillName.replaceAll(".", " ");
        //skillName = skillName.replaceAll("-", " ");

        skillName = skillName.replaceAll("&", " ");
        skillName = skillName.trim();
      //  console.log("skill name  ---", skillName);
        let words = skillName.split(" ");
        for (let i = 0; i < words.length; i++) {
          words[i] = words[i][0].toUpperCase() + words[i].substr(1);
        }
        words = words.join(" ");
       // console.log("back to sentence", words);
        return words;
      }
    },
    createSkills() {
      if (this.$refs.createEditSkillForm.validate()) {
        (this.skill.parentSkillId = this.parentId),
          (this.skill.skillLevel = this.level);
        console.log("new skills ", this.skill);

        this.skill.skillName = this.modifySkillName(this.skill.skillName);
        // skill synnons acant be same as main skill name
        // modify skill name to caps

        if (this.skill.skillSynonyms.length > 0) {
          for (let i = 0; i < this.skill.skillSynonyms.length; i++) {
            this.skill.skillSynonyms[i] = this.modifySkillName(
              this.skill.skillSynonyms[i]
            );
          }
            console.log('skill synonms moddufled  ----' ,this.skill);
         let synonums= Object.assign([], this.skill.skillSynonyms);
          for (let i = 0; i < this.synonums; i++) {
            synonums = synonums.filter(
              skill=> skill != this.skill.skillName
            );
          }
          console.log('skill modified ----' ,synonums);
        }
        
        console.log("update skills  create ", this.skill);

        console.log("skill name  ---", this.skill);
        this.$store.dispatch("skillTree/createSkill", {
          skill: this.skill,
          cb: this.cb
        });
      }
    },
    removeSynonyms(synonym) {
      //  console.log("synonym to remove ----", synonym);
      this.skill.skillSynonyms.splice(
        this.skill.skillSynonyms.indexOf(synonym),
        1
      );
      this.skill.skillSynonyms = [...this.skill.skillSynonyms];
    },
    editSkills() {
      if (this.$refs.createEditSkillForm.validate()) {
        this.skill.skillName = this.modifySkillName(this.skill.skillName);
        
      
        if (this.skill.skillSynonyms.length > 0) {
          for (let i = 0; i < this.skill.skillSynonyms.length; i++) {
          this.skill.skillSynonyms[i] = this.modifySkillName(
            this.skill.skillSynonyms[i]
          );
        }  
        console.log('skill synonms moddufled  ----' ,this.skill);
          let synonums= Object.assign([], this.skill.skillSynonyms);
          for (let i = 0; i < this.synonums; i++) {
            
            synonums = synonums.filter(
              (skillName) => skillName != this.skill.skillName
            );
          }
          console.log('skill modified ----' ,synonums);
        }
          
        this.$store.dispatch("skillTree/updateSkill", {
          skill: this.skill,
          cb: this.cb
        });
      }
      // console.log('updated skill ' ,  this.skill);
    },
    getSkillUsingId() {
      this.$store.dispatch("skillTree/getSkillUsingId", {
        skillId: this.skillId
      });
    },
    closeSnackbar() {
      console.log("close snack bar called");
      this.$store.dispatch("skillTree/closeNotice", 0);
    }
  }
};
</script>

<style>
.v-dialog {
  border: 2px solid rgb(0, 0, 0);
}
</style>
