<template>

  <v-tooltip right dark>
    <template v-slot:activator="{ on, attrs }">
      <v-icon color="black" dark v-bind="attrs" v-on="on">
        help_outline
      </v-icon>
    </template>
    <span>{{ tooltipText }}</span>
  </v-tooltip>
</template>

<script>
export default {
    name:"Tooltip",
    props:["tooltipText"]
};
</script>

<style></style>
