<template>
  <v-container fluid>
    <v-card :color="cardSelected ? '#EBEEF0' : 'white'" width="280px">
      <v-card-actions>
        <v-row no-gutters>
          <v-col cols="8">
            <!-- <p class="text-h6 font-weight-bold text--wrap">
               {{skillEntitiy.skillName}} -->
              <!-- {{ skillEntitiy.skillLevel }} Skill name -->
            <!-- </p> -->
          </v-col>
          <v-col cols="2">
            <EditSkill
              :level="skillEntitiy.skillLevel"
              :parentId="skillEntitiy.parentSkillId"
              :skillId="skillEntitiy.skillId"
            />
          </v-col>
          <v-col cols="2">
            <v-icon color="red" @click="deleteSkill">
              delete
            </v-icon>
          </v-col>
        </v-row>
      </v-card-actions>
      <v-card-text @click="cardClicked" class="mt-n7">
        <v-row>
          <v-col cols="12">
            <div class="text-h6 font-weight-bold mx-1">
              {{
                skillEntitiy.skillName
              }}
            </div>
          </v-col>
        </v-row>
        <v-row class="mt-1" v-if="skillEntitiy.skillSynonyms.length > 0">
          <div class="text-h6 font-weight-bold text--black">
            <!-- Skill synonyms -->
          </div>
        </v-row>
        <v-row>
          <v-col>
             <div
            class="text-h7"
            v-for="(skill, index) in skillEntitiy.skillSynonyms"
            :key="index"
          >
           <v-chip color="green" class="mx-1 my-1"
      outlined>
                {{ skill }} 
              </v-chip>
          
          </div>
          </v-col>
         
        </v-row>
      </v-card-text>
    </v-card>
  </v-container>
</template>
<script>
import EditSkill from "./CreateSkill.vue";
import { mapState } from "vuex";
export default {
  components: {
    EditSkill
  },
  data() {
    return {};
  },
  computed: {
    ...mapState("skillTree", {
      selectedL1Skill: "selectedL1Skill",
      selectedL2Skill: "selectedL2Skill",
      selectedL3Skill: "selectedL3Skill"
    })
  },
  name: "SkillCard",
  props: ["skillEntitiy", "cardSelected"],

  methods: {
    deleteSkill() {
      console.log("delte skilll called");
      this.$store.dispatch("skillTree/deleteSkill", {
        skillId: this.skillEntitiy.skillId,
        deleteConfirmation: false,
        skillLevel: this.skillEntitiy.skillLevel
      });
    },
    cardClicked(event) {
      console.log(event);
      this.$store.dispatch("skillTree/selectedSkill", this.skillEntitiy);

      this.$store.dispatch("skillTree/getChildrenSkills", {
        skillId: this.skillEntitiy.skillId,
        skillLevel: this.skillEntitiy.skillLevel
      });
    }
  }
};
</script>

<style>
.v-chip__content{
  width: 85%;
  word-wrap: break-word;
 /* text-overflow: ellipsis; */
 /* white-space: nowrap;
  display: inline-block; */
}
</style>
