<template>
  <v-container fluid>
    <v-row>
       <div class="text-h4 font-weight-bold mx-9">
      Manage Skills
       </div>
    </v-row >
    <DeleteAlertBox :dialog="deleteAlertBoxSatus" @onOk="confirmDelte" @onCancel="onCancel"/>
    <v-row justify="space-between" class="my-3" dense>
      <v-col cols="3">
      <v-card width="300px" height="600px" class="scroll">
        <v-row justify="center">
          <v-spacer></v-spacer>
          <v-col cols="6">
            <div class="text-h6 font-weight-bold mx-6">
              L1 Skills
            </div>
          </v-col>
          <v-col cols="3">
            <CreateSkills level="L1" :parentId="null" />
          </v-col>
        </v-row>
        <v-card-text class="mt-n9">
          <div v-if="l1Skills.length >0">
            <v-list v-for="(skillEntity, index) in l1Skills" :key="index">
              <SKillCard :skillEntitiy="skillEntity" :cardSelected="skillEntity.isSelected!=undefined?skillEntity.isSelected:null"/>
            </v-list>
          </div>
          <v-card v-else rounded outlined>
            <v-row justify="center">
              <div class="text-h7 font-weight-bold mx-8">
              Click + to add skills
              </div>
            </v-row>
          </v-card>
        </v-card-text>
      </v-card>
      </v-col>
      <v-col cols="3">
      <v-card width="300px" height="600px" class="scroll">
        <v-row justify="center">
          <!-- <v-spacer></v-spacer> -->
          <v-col cols="6">
            <div class="text-h6 font-weight-bold mx-6">
              L2 Skills
            </div>
          </v-col>
          <v-col cols="3" v-if="selectedL1Skill!=null">
            <CreateSkills level="L2" :parentId="selectedL1Skill?selectedL1Skill.skillId:null"/>
          </v-col>
        </v-row>
        <v-card-text class="mt-n9">
          <div v-if="l2Skills.length > 0">
            <v-list v-for="(skillEntity, index) in l2Skills" :key="index">
              <SKillCard :skillEntitiy="skillEntity" :cardSelected="skillEntity.isSelected!=undefined?skillEntity.isSelected:null" />
            </v-list>
          </div>
          <v-card v-else rounded outlined class="my-2">
            <v-row justify="center">
              <div class="text-h7 font-weight-bold mx-8">
              Click + to add skills
              </div>
            </v-row>
          </v-card>
        </v-card-text>
      </v-card>
      </v-col>
      <v-col cols="3">
      <v-card width="300px" height="600px" class="scroll">
        <v-row justify="center">
          <v-col cols="6">
            <div class="text-h6 font-weight-bold mx-6">
              L3 Skills
            </div>
          </v-col>
          <v-col cols="3" v-if="selectedL2Skill!=null">
            <CreateSkills level="L3" :parentId="selectedL2Skill?selectedL2Skill.skillId:null" />
          </v-col>
        </v-row>
        <v-card-text class="mt-n9">
          <div v-if="l3Skills.length > 0">
            <v-list v-for="(skillEntity, index) in l3Skills" :key="index">
              <SKillCard :skillEntitiy="skillEntity" :cardSelected="skillEntity.isSelected!=undefined?skillEntity.isSelected:null"/>
            </v-list>
          </div>
           <v-card v-else rounded outlined class="my-2">
            <v-row justify="center">
              <div class="text-h7 font-weight-bold mx-8">
              Click + to add skills
              </div>
            </v-row>
          </v-card>
        </v-card-text>
      </v-card>
      </v-col>
      <v-col cols="3">
      <v-card width="300px" height="600px" class="scroll">
        <v-row justify="center">
          <v-col cols="6">
            <div class="text-h6 font-weight-bold mx-6">
              L4 Skills
            </div>
          </v-col>
          <v-col cols="3" v-if="selectedL3Skill!=null">
            <CreateSkills level="L4" :parentId="selectedL3Skill?selectedL3Skill.skillId:null" />
          </v-col>
        </v-row>
        <v-card-text class="mt-n9">
          <div v-if="l4Skills.length > 0">
            <v-list v-for="(skillEntity, index) in l4Skills" :key="index">
              <SKillCard :skillEntitiy="skillEntity" />
            </v-list>
          </div>
            <v-card v-else rounded outlined class="my-2">
            <v-row justify="center">
              <div class="text-h7 font-weight-bold mx-8">
              Click + to add skills
              </div>
            </v-row>
          </v-card>
        </v-card-text>
      </v-card>    
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import CreateSkills from "../../../components/CreateSkill.vue";
import SKillCard from "../../../components/skillCard.vue";
import DeleteAlertBox from '../../../components/deleleAlterBox.vue'
import { mapState } from "vuex";
export default {
  name: "ManageSkills",
  components: {
    CreateSkills,
    SKillCard,
    DeleteAlertBox
  },
  data() {
    return {
      deleteDailog:true
    }
  },
  created() {
    this.$store.dispatch("skillTree/getL1Skills");
  },
  computed: {
    ...mapState("skillTree", {
      skill: "skill",
      l1Skills: "l1Skills",
      l2Skills: "l2Skills",
      l3Skills: "l3Skills",
      l4Skills: "l4Skills",
      selectedL1Skill: "selectedL1Skill",
      selectedL2Skill: "selectedL2Skill",
      selectedL3Skill: "selectedL3Skill",
      loading: "loading",
      deleteAlertBoxSatus:'deleteAlertBoxSatus',
      afterOk:'afterOk'

    })
  },
  methods:{
    onCancel(){
      console.log('on cancel called');
      this.$store.dispatch('skillTree/closeDeleteDailog')
    },
    confirmDelte() {
      console.log("Inside alert box of manage skills");
      console.log('------after ok---------' ,this.afterOk.skillId);
      this.$store.dispatch("skillTree/deleteSkill", {
        skillId: this.afterOk.skillId,
        deleteConfirmation:true,
        skillLevel:this.afterOk.skillLevel
      });
    }
  }
};
</script>
<style>
.scroll {
   overflow-y: scroll;
   overflow-x:hidden
}
</style>
